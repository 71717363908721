import {
  type IService,
  type IServicePriceSettings,
  type IServicePriceSettingsParam, ServicePriceSettingsActions,
  ServiceTypes
} from '@types';
import { hmToTMinutes } from '@utils/minutes';

export function preparePriceSettings(settings: IServicePriceSettings[] = []) {
  return settings.map(s => ({
    ...s,
    dateStart: s.dateStart && new Date(s.dateStart),
    dateEnd: s.dateEnd && new Date(s.dateEnd)
  }));
}

export function filterActualPriceSettings(settings: IServicePriceSettings[] = []) {
  const now = new Date();
  return settings.map(s => ({ ...s, parameters: s.parameters.filter(p => !p.isDisabled) }))
    .filter(s => s.parameters.length)
    .filter(s => s.action === ServicePriceSettingsActions.Const || !s.dateEnd || now < s.dateEnd);
  // TODO if (now - dateEnd < 7 days) check on weekdays
}


function getMinRentPrice(p: IServicePriceSettingsParam) {
  const durationMinutes = hmToTMinutes(+p.durationMinutes, +p.durationHour);
  const timeDivider = Math.min(durationMinutes, 60) / 60;
  return +p.amount * timeDivider;
}

function getGetPriceFunction(type: ServiceTypes): (p: IServicePriceSettingsParam) => number {
  switch (type) {
    case ServiceTypes.Rent: return getMinRentPrice;
    default: return (p: IServicePriceSettingsParam) => +p.amount;
  }
}

export function getMinPriceByPriceSettings(settings: IServicePriceSettings[] = [], type: ServiceTypes) {
  const getPrice = getGetPriceFunction(type);
  const minPrice = settings.reduce(
    (r, s) => Math.min(r, s.parameters.reduce(
      (res, p) => Math.min(res, getPrice(p)),
      Number.MAX_SAFE_INTEGER)
    ),
    Number.MAX_SAFE_INTEGER,
  );
  return minPrice === Number.MAX_SAFE_INTEGER ? 0 : minPrice;
}


export function getMinPriceByService(service: IService) {
  return getMinPriceByPriceSettings(filterActualPriceSettings(preparePriceSettings(service.priceSettings)), service.type);
}