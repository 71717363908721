<script setup lang="ts">
import { minutesToHM } from '@utils/minutes';

const props = withDefaults(defineProps<{
  h?: number | string;
  m?: number | string;
}>(), {
  h: 0,
  m: 0,
});

const total = computed(() => {
  const { m, h } = +props.m > 59 ? minutesToHM(+props.m, +props.h) : { h: +props.h, m: +props.m };
  return [{ v: h, label: 'word.hour.letter'}, { v: m, label: 'word.minute.short'}].filter(({v}) => +v);
})
</script>

<template lang="pug">
span {{ total.map(({v, label}) => `${v}${label ? ' ' + $t(label) : ''}`).join(' ') }}
</template>
